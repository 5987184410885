import { render, staticRenderFns } from "./NextDrawer.vue?vue&type=template&id=63bb6b56&scoped=true"
import script from "./NextDrawer.vue?vue&type=script&lang=js"
export * from "./NextDrawer.vue?vue&type=script&lang=js"
import style0 from "./NextDrawer.vue?vue&type=style&index=0&id=63bb6b56&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "63bb6b56",
  null
  
)

export default component.exports