<template>
    <div class="file-list-component">
        <div class="file-list-table">
            <el-table ref="fileListTable" :data="list" @selection-change="handleSelectionChange" @sort-change="handleSortChange" row-key="docId" style="width: 100%">
                <el-table-column type="selection" fixed :selectable="handleSetSelectionEnable" header-align="center" align="center"></el-table-column>
                <el-table-column label="文件名" fixed min-width="320" sortable="custom" column-key="FILENAME" width="340" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <div class="file-icon">
                            <svg-icon :name="`ic-${config.FILE_TYPE_MATCH['.' + scope.row.fileType] ? config.FILE_TYPE_MATCH['.' + scope.row.fileType].icon : ''}`"></svg-icon>
                        </div>
                        <template v-if="scope.row.docStatus === 'pre-translated'">
                            <router-link :to="`/workbench/editer?id=${scope.row.docId}`" class="table-link">{{ scope.row.originalFilename }}</router-link>
                        </template>
                        <template v-else>{{ scope.row.originalFilename }}</template>
                    </template>
                </el-table-column>
                <el-table-column label="机翻状态" min-width="130">
                    <template slot-scope="scope">
                        <template v-if="scope.row.docStatus === 'pre-translated'">
                            <svg-icon name="ib-check-circle" class="fonts-15 color-success"></svg-icon>
                            <span class="margin-l-10 color-666666">{{scope.row.docStatusDesc}}</span>
                        </template>
                        <template v-else-if="scope.row.docStatus === 'limited'">
                            <svg-icon name="ib-warning-o" class="fonts-15 color-error"></svg-icon>
                            <span class="margin-x-10">字符流量不足</span><br/>
                            <el-tooltip content="购买成功后，回到此页面点击重译" placement="top" v-permission="`project:detailRouter:buy`">
                                <el-button type="text" class="button-link" @click="showPayCharDialog">购买字符包</el-button>
                            </el-tooltip>
                            <el-button type="text" class="button-link" v-permission="`project:detailRouter:retry`" @click="executeAction('retranslate', scope.row)">重译</el-button>
                        </template>
                        <template v-else-if="scope.row.docStatus.indexOf('error') > -1">
                            <svg-icon name="ib-warning-o" class="fonts-15 color-error"></svg-icon><span class="color-666666 margin-l-10">{{scope.row.docStatusDesc}}</span>
                        </template>
                        <template v-else>
                            <i class="el-icon-loading fonts-15"></i><span class="color-666666 margin-l-10">{{scope.row.docStatusDesc}}</span>
                        </template>
                    </template>
                </el-table-column>
                <el-table-column label="译后编辑进度" min-width="160" header-align="center" align="center">
                    <template slot="header">
                        <el-tooltip content="在译后编辑页面中，对需要处理的译文进行编辑后点击右侧的【确认】键，译后编辑进度便会进行更新" placement="top">
                            <span>译后编辑进度 <svg-icon name="ib-question"></svg-icon></span>
                        </el-tooltip>
                    </template>
                    <template slot-scope="scope">
                        <template v-if="scope.row.docStatus === 'pre-translated'">
                            <div class="task-progress" style="margin-top: 4px;max-width: 150px;">
                                <el-progress :percentage="scope.row.progress" :stroke-width="20" color="#D3F4EC" :show-text="false"></el-progress>
                                <div class="percentage">{{scope.row.progress}}%</div>
                            </div>
                        </template>
                        <template v-else>—</template>
                    </template>
                </el-table-column>
                <el-table-column label="字数 / 字符" min-width="100" header-align="center" align="center">
                    <template slot-scope="scope">
                        <span>{{scope.row.sourceWords || '-'}}字 / {{scope.row.sourceCharacters || '-'}}字符</span>
                    </template>
                </el-table-column>
                <el-table-column label="上传时间"  min-width="130" header-align="center" align="center">
                    <template slot-scope="scope">
                        <svg-icon name="ib-upload-file" className="fonts-18 color-999 margin-r-10"></svg-icon>{{getTimeText(scope.row.createTime)}}
                    </template>
                </el-table-column>
                <el-table-column label="操作" fixed="right" header-align="center" align="center" width="120">
                    <template slot-scope="scope">
                        <div class="flex flex-justify-center" v-if="scope.row.docStatus == 'pre-translated' || scope.row.docStatus === 'limited' || scope.row.docStatus.indexOf('error')>-1">
                            <template v-if="scope.row.docStatus == 'pre-translated'">
                                <el-dropdown placement="bottom" trigger="hover" class="download-operate-dropdown" @command="(command)=>handleMoreCommand(command, scope.row)">
                                    <div class="operate-btn flex flex-justify-between flex-align-center"><span><svg-icon name="ib-download-1"></svg-icon></span></div>
                                    <el-dropdown-menu slot="dropdown">
                                        <el-dropdown-item command="target" v-permission="`project:detailRouter:downloadTranslations`">下载译文</el-dropdown-item>
                                        <el-dropdown-item command="bilingual" v-permission="`project:detailRouter:downloadBilingual`" v-if="['xlf', 'xliff', 'sdlxlf', 'sdlxliff'].indexOf(scope.row.fileType) < 0">
                                            下载双语 <pro-icon :size="14" style="margin-bottom:-2px;margin-left:5px;" v-if="['personal', 'pro-limited'].indexOf(userInfo.accountType)>-1"></pro-icon>
                                        </el-dropdown-item>
                                        <el-dropdown-item command="source" v-permission="`project:detailRouter:downloadOriginal`">下载原文</el-dropdown-item>
                                        <el-dropdown-item command="tmx" v-permission="`project:detailRouter:downloadTMX`">下载TMX</el-dropdown-item>
                                    </el-dropdown-menu>
                                </el-dropdown>
                            </template>
                            <el-tooltip content="删除文件" placement="top">
                                <div class="operate-btn" v-permission="`project:detailRouter:delete`" @click="executeAction('delete', scope.row)"><svg-icon name="ib-trash-o-1"></svg-icon></div>
                            </el-tooltip>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
        </div>
    </div>
</template>
<script>
import {mapState} from 'vuex';
import moment from'moment';
import config from '@/utils/config';
import ProIcon from "@/components/icon/ProIcon";
export default {
    components: {
        ProIcon,
    },
    props: {
        list: {
            type: Array,
            default: []
        },
        reset: '',
    },
    data() {
        return {
            config,
        }
    },
    computed: {
        ...mapState({
            userInfo: state => state.user.userData,
        }),
    },
    methods: {
        executeAction(key,data){
            this.$emit('action', key, data);
        },
        handleMoreCommand(command,item){
            if(command === 'bilingual'){
                if(['personal', 'pro-limited'].indexOf(this.userInfo.accountType)>-1){
                    this.$router.push('/account/vip');
                    return;
                }
            }
            this.executeAction(command, item);
        },
        handleSelectionChange(selection) {
            this.$emit('action', 'updateSelected', selection);
        },
        handleSortChange({column, prop, order}) {
            this.$emit('action', 'updateSort', {sortBy: column.columnKey, sortMode: order === 'ascending' ? 'ASC' : order === 'descending' ? 'DESC' : ''})
        },
        showPayCharDialog() {
            this.$router.push({path:'/account/vip', query: {view: 'char', child: true}});
        },
        handleSetSelectionEnable(row, index) {
            return row.docStatus === "pre-translated";
        },
        getTimeText(t) {
            let tYear = moment(t).year();
            let cYear = moment().year();
            if(tYear === cYear) {
                return moment(t).format(`MM-DD HH:mm`);
            }else {
                return moment(t).format(`YYYY-MM-DD`);
            }
        },
    },
    watch: {
        'reset' (newVal, oldVal) {
            this.$refs.fileListTable.clearSelection();
        },
    }
}
</script>
<style lang="scss" scoped>
.file-list-table{
    .table-link {
        color: #000;
        &:hover {
            text-decoration: underline;
        }
    }
    ::v-deep .el-table{
        &::before {
            height: 0;
            width: 0;
            bottom: -10000px;
            background-color: transparent;
        }
        th {
            padding: 10px 0;
            font-size: 14px;
            color: #999999;
        }
        .el-table__row {
            td {
                vertical-align: middle;
                background-color: #ffffff;
                font-size: 14px;
                padding: 10px 0;
                
                .cell {
                    .el-button--text {
                        font-size: 14px;
                        svg {
                            font-size: 18px;
                        }
                    }
                }
            }
            &:hover {
                td {
                    background-color: #fff;
                }
            }
        }
    }
    .task-progress {
        display: inline-block;
        width: 198px;
        position: relative;
        .percentage {
            width: 100%;
            position: absolute;
            top: 0;
            line-height: 20px;
            text-align: center;
            color: #1AC49C;
            font-weight: 500;
        }
    }
}
.file-icon {
    display: inline-block;
    height: 32px;
    width: 32px;
    text-align: center;
    line-height: 32px;
    background-color: #F8F8F8;
    border-radius: 50px;
    font-size: 18px;
    margin-right: 12px;
}
.operate-btn {
    font-size: 18px;
    cursor: pointer;
    color: #000;
    padding: 0 5px;
}
</style>
