<!-- 
    选择用户组件
-->
<template>
    <el-dialog :title="$t('component.UserSelectorDialog_title')" :visible.sync="show" destroy-on-close @open="openModalEvent" @closed="closedModalEvent" :width="'430px'" top="20px">
        <el-form label-position="top" style="padding: 0px 34px;">
            <el-form-item :label="$t('component.UserSelectorDialog_form_name_label')" required>
                <el-select v-model="value" @change="onSelected" remote filterable reserve-keyword clearable :remote-method="searchEvent" :placeholder="$t('component.UserSelectorDialog_form_name_placeholder')" style="width: 100%;">
                    <template v-for="item in options">
                        <el-option class="user-selector-option" :key="item.memberId" :value="item.memberId" :label="item.memName">
                            <div class="flex flex-algin-center">
                                <el-avatar :src="item.memAvatar" icon="el-icon-user" :size="28"></el-avatar>
                                <div class="margin-l-10">
                                    <div>
                                        <span>{{item.memName}}</span>
                                        <span class="margin-l-10 color-666666 fonts-12">{{item.memPhone}}</span>
                                    </div>
                                    <div class="margin-t-10 color-666666 fonts-12 ">{{item.roleName}}</div>
                                </div>
                            </div>
                        </el-option>
                    </template>
                </el-select>
            </el-form-item>
        </el-form>
        <div slot="footer" class="text-center">
            <el-button plain round size="small" @click="show = false;">{{ $t('component.UserSelectorDialog_button_cancel') }}</el-button>
            <el-button type="primary" round size="small" :loading="loading" @click="submitAllotUser">{{ $t('component.UserSelectorDialog_button_submit') }}</el-button>
        </div>
    </el-dialog>
</template>
<script>
export default {
    name: "UserSelectorDialog",
    props: {
        multiple: false,
        loading: false,
        params: null,
        selected:{
            type: [Number, String, Array],
            default: '',
        },
        options: {
            type: Array,
            default: [],
        },
    },
    data() {
        return {
            show: false,
            value: '',
            searchTimer: null,
            user: null,
        }
    },
    methods: {
        setShow(val){
            this.show = val;
        },
        searchEvent(query) {
            let self = this;
            if(this.searchTimer){
                clearTimeout(this.searchTimer);
                this.searchTimer = null;
            }
            this.searchTimer = setTimeout(()=>{
                self.$emit('search', query, this.params);
            }, 500);
        },
        openModalEvent(){
            let self = this;
            this.$emit('search', '', this.params);
            setTimeout(() => {
                self.value = self.selected;
            }, 1500);
        },
        closedModalEvent(){
            if(this.searchTimer){
                clearTimeout(this.searchTimer);
                this.searchTimer = null;
            }
        },
        onSelected(id) {
            // console.log('xxx:::::',id, this.options)
            // this.user = this.options.filter(item => item.memberId === this.value)[0];
        },
        submitAllotUser(){
            this.user = this.options.filter(item => item.memberId === this.value)[0];
            this.$emit('change', this.user, this.params);
        }
    },
    watch: {
        selected(newVal, oldVal){
            this.value = newVal;
        }
    }

}
</script>
<style lang="scss" scoped>
.user-selector-option {
    height: auto;
    line-height: 1;
    padding: 8px 20px;
    color: #000000;
}
</style>
