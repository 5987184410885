<!--
    抽屉组件
    <next-drawer :visible.sync="nextDrawer.visible" :modal="false" showFooter title="拆分文件"></next-drawer>
-->
<template>
    <transition 
        name="drawer-fade" 
        :enter-active-class="animated.enter"
        :leave-active-class="animated.leave"
        @after-leave="afterLeave">
        <div class="drawer-wrapper" 
            :class="[direction]"
            v-show="visible"
            :style="isHorizontal ? `width: ${drawerSize}` : `height: ${drawerSize}`">
            <header class="drawer-header">
                <slot name="title">
                    <div class="title">{{title}}</div>
                </slot>
                <div class="flex-1">
                    <slot name="action"></slot>
                </div>
                <div class="drawer-close-btn" @click="closeDrawer" v-if="showClose">
                    <svg-icon name="ib-close"></svg-icon>
                </div>
            </header>
            <div class="drawer-body">
                <slot>
                    <template v-for="i in 100">
                        <br :key="i"/>
                    </template>
                </slot>
            </div>
            <footer class="drawer-footer" v-if="showFooter">
                <slot name="footer">
                    <div class="width-full text-center">
                        <el-button size="small" plain round @click="closeDrawer">{{ $t('component.NextDrawer_button_close') }}</el-button>
                        <el-button size="small" round type="primary">{{ $t('component.NextDrawer_button_confirm') }}</el-button>
                    </div>
                </slot>
            </footer>
        </div>
    </transition>
</template>
<script>
export default {
    name: 'NextDrawer',
    props: {
        /**
         * visible 是否显示 Drawer，支持 .sync 修饰符
         */
        visible: {
            type: Boolean,
            default: false,
        },
        /**
         * Drawer 窗体的大小, 当使用 number 类型时, 以像素为单位, 当使用 string 类型时, 请传入 'x%', 否则便会以 number 类型解释
         */
        size: {
            type: [Number, String],
            default: '30%',
        },
        /**
         * Drawer 打开的方向，rtl（右到左） / ltr（左到右） / ttb（上到下） / btt（下到上）
         */
        direction: {
            type: String,
            default: 'rtl',
            validator(val){
                return ['ltr', 'rtl', 'ttb', 'btt'].indexOf(val) !== -1;
            },
        },
        modal: {
            type: Boolean,
            default: true,
        },
        title: {
            type: String,
            default: '',
        },
        wrapperClosable: {
            type: Boolean,
            default: true,
        },
        showClose: {
            type: Boolean,
            default: true,
        },
        showFooter: {
            type: Boolean,
            default: false,
        }

    },
    computed: {
        isHorizontal() {
            return this.direction === 'rtl' || this.direction === 'ltr';
        },
        drawerSize() {
            return typeof this.size === 'number' ? `${this.size}px` : this.size;
        },
        animated() {
            let animateEnter= '';
            let animateLeave= '';
            switch(this.direction){
                case 'rtl':
                    animateEnter = 'fadeInRight';
                    animateLeave = 'fadeOutRight'; 
                    break;
                case 'ltr':
                    animateEnter = 'fadeInLeft';
                    animateLeave = 'fadeOutLeft'; 
                    break;
                case 'btt':
                    animateEnter = 'fadeInUp';
                    animateLeave = 'fadeOutDown'; 
                    break;
                case 'ttb':
                    animateEnter = 'fadeInDown';
                    animateLeave = 'fadeOutUp'; 
                    break;
            }
            return {
                enter: `animated ${animateEnter} faster`,
                leave: `animated ${animateLeave} faster`,
            };
        },
    },
    data() {
        return {
            show: false,
        }
    },
    watch: {
        visible(val, oldVal){
            // if(val){
                this.show = val;
            // }
            if (this.show && this.modal){
                this.showModalMask();
            }else{
                this.hideModalMask();
            }
        }  
    },
    methods: {
        showModalMask(){
            let self = this;
            let node = document.createElement('div');
            node.setAttribute('class', 'modal-mask');
            if (this.wrapperClosable){
                node.addEventListener('click', this.closeDrawer);
            }
            document.body.appendChild(node);
        },
        hideModalMask(){
            let node = document.querySelector('.modal-mask');
            if(node && node.parentNode){
                node.parentNode.removeChild(node);
            }
        },
        closeDrawer() {
           this.$emit('update:visible', false); 
           this.hideModalMask();
           this.show = false;
        },
        afterLeave(){
            this.$emit('closed');
        }
    },
    mounted() {
        

    },
    destroyed() {
        this.hideModalMask();
    }

}
</script>
<style lang="scss" scoped>
.drawer-wrapper {
    position: fixed;
    background-color: #fff;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
    height: 100%;
    z-index: 1000;
    display: flex;
    flex-direction: column;
    border-left: 1px solid #E6E6E6;
    &.rtl{
        top: 0;
        bottom: 0;
        right: 0;
    }
    &.ltr{
        top: 0;
        bottom: 0;
        left: 0;
    }
    &.ttb{
        top: 0;
        left: 0;
        right: 0;
    }
    &.btt{
        bottom: 0;
        left: 0;
        right: 0;
    }
    .drawer-header {
        display: flex;
        height: 45px;
        padding-left: 20px;
        align-items: center;
        box-sizing: border-box;
        border-bottom: 1px solid #E6E6E6;
        background-color: #F8F8F8;
        font-size: 14px;
        .drawer-close-btn{
            width: 45px;
            height: 100%;
            line-height: 45px;
            text-align: center;
            cursor: pointer;
            &:hover{
                background-color: #E6E6E6;
            }
        }
        
    }
    .drawer-body{
        flex: 1;
        overflow-y: auto;
    }
    .drawer-footer {
        height: 50px;
        display: flex;
        align-items: center;
        box-sizing: border-box;
        border-top: 1px solid #E6E6E6;
        background-color: #F8F8F8;
        padding: 0 20px;
    }
}
</style>
