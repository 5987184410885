<template>
    <div class="file-list-inside">
        <div class="file-list-inside-table">
            <el-table ref="fileListTable" :data="list" @expand-change="expandChangeEvent" @selection-change="handleSelectionChange" @sort-change="handleSortChange" row-key="docId" style="width: 100%">
                <el-table-column type="expand" fixed header-align="center" align="center" width="34">
                    <template slot-scope="scope">
                        <div class="expand-table">
                            <el-table :data="scope.row.tasks || []" :show-header="false" :span-method="handleSpanMethod" :row-class-name="handleRowClassName" :empty-text="scope.row.docStatus !== 'pre-translated' ? '文档解析或预翻译未完成，请稍后查看' : '暂无数据'">
                                <el-table-column width="34" fixed></el-table-column>
                                <el-table-column width="42" fixed></el-table-column>
                                <el-table-column width="340" fixed>
                                    <template slot-scope="taskScope">
                                        <div style="padding-left: 44px;">{{taskScope.row.taskName}}句段</div>
                                    </template>
                                </el-table-column>
                                <el-table-column width="220"></el-table-column>
                                <el-table-column min-width="100"> 
                                    <template slot-scope="taskScope">
                                        <span>{{taskScope.row.range.wordCount}}字</span>
                                    </template>
                                </el-table-column>
                                <!-- <el-table-column label="机翻状态" min-width="130"></el-table-column> -->
                                <el-table-column align="center" min-width="200">
                                    <template slot-scope="taskScope">
                                        <div class="workflow-step-item" :class="{'active': taskScope.row.state === 1, 'finashed': taskScope.row.state === 2}">
                                            <!-- <div class="point"></div> -->
                                            <div class="task-progress" style="margin-top: 4px; max-width: 150px;">
                                                <el-progress :percentage="taskScope.row.progress" :stroke-width="20" color="#D3F4EC" :show-text="false"></el-progress>
                                                <div class="percentage">{{taskScope.row.progress}}%</div>
                                            </div>
                                            <div class="margin-l-10">{{taskScope.row.typeDesc}}</div>
                                        </div>
                                    </template>
                                </el-table-column>
                                <el-table-column min-width="160">
                                    <template slot-scope="taskScope">
                                        <svg-icon name="ib-user-plus" className="fonts-18 color-999 margin-r-10"></svg-icon>
                                        <template v-if="taskScope.row.assignee && taskScope.row.assignee.length">
                                            <el-tooltip :content="taskScope.row.assignee.length ? '变更负责人' : '分配负责人'" placement="top">
                                                <span class="cursor-pointer table-link" @click="assignCommandEvent('assign', taskScope)">
                                                    <template v-for="(assignee, assigneeIndex) in taskScope.row.assignee">
                                                        <template v-if="assigneeIndex < 2">{{assignee.alias}}</template>
                                                        <template v-if="assigneeIndex < taskScope.row.assignee.length - 1 && assigneeIndex < 1 ">,</template>
                                                    </template>
                                                    <span class="margin-l-5" v-if="taskScope.row.assignee.length > 2">等{{taskScope.row.assignee.length}}人</span>
                                                </span>
                                            </el-tooltip>
                                        </template>
                                        <template v-else><span class="color-999 cursor-pointer" @click="assignCommandEvent('assign', taskScope)">未分配</span></template>
                                        <template v-if="taskScope.row.actively">
                                            <template v-if="['REJECTED', 'ASSIGNED'].indexOf(taskScope.row.status) > -1">
                                                <span :class="taskScope.row.status">
                                                    <template v-if="taskScope.row.status === 'REJECTED'">
                                                        <el-tooltip :content="'拒绝原因：'+taskScope.row.remark" placement="top">
                                                            <span>（{{taskScope.row.returned ? '退回' : ''}}{{taskScope.row.statusDesc}}）</span>
                                                        </el-tooltip>
                                                    </template>
                                                    <template v-else>（{{taskScope.row.returned ? '退回' : ''}}{{taskScope.row.statusDesc}}）</template>
                                                </span>
                                            </template>
                                        </template>
                                        <template v-else>
                                            <span class="color-999" v-if="taskScope.row.returned">（退回）</span>
                                        </template>
                                    </template>
                                </el-table-column>
                                <el-table-column min-width="160" class-name="eidt-col">
                                    <template slot-scope="taskScope">
                                        <div class="height-full flex flex-align-center" :class="{'timeout': taskScope.row.overdue}">
                                            <svg-icon name="ib-alarm" className="fonts-18 color-999 margin-r-10"></svg-icon>
                                            <template v-if="taskScope.row.status === 'FINISHED' || !$checkPermission('project:detailRouter:deadline')">
                                                <span v-if="taskScope.row.deadlineTime">{{getTimeText(taskScope.row.deadlineTime)}}</span>
                                                <span v-else class="color-999">未设置</span>
                                            </template> <!-- format="yyyy-MM-dd HH:mm" -->
                                            <template v-else>
                                                <el-date-picker popper-class="hide-time"
                                                    v-model="taskScope.row.deadlineTime"
                                                    type="datetime"
                                                    :editable="false"
                                                    :clearable="false"
                                                    placeholder="选择截止时间"
                                                    prefix-icon="null"
                                                    default-time="12:00:00"
                                                    format="MM-dd HH:mm"
                                                    :picker-options="pickerOptions(scope.row.createTime)"
                                                    @change="val => setDeadLineTime(val, scope, taskScope)" style="width: 100%;">
                                                </el-date-picker>
                                            </template>
                                        </div>
                                    </template>
                                </el-table-column>
                                <!-- <el-table-column width="100"> -->
                                    <!-- 译后编辑率 --> -
                                <!-- </el-table-column> -->
                                <el-table-column width="110" fixed="right">
                                    <template slot-scope="taskScope">
                                        <template v-if="taskScope.row.status !== 'FINISHED' && $checkPermission('project:detailRouter:assignTask')">
                                            <el-tooltip content="查看联系方式" placement="top">
                                                <el-button type="text" class="button-text" @click="assignCommandEvent('info', taskScope)"><svg-icon name="ib-ecard"></svg-icon></el-button>
                                            </el-tooltip>
                                        </template>
                                        <template v-if="taskScope.row.status == 'FINISHED' && $checkPermission('project:detailRouter:returnToPrevTask')">
                                            <el-tooltip content="退回至此阶段" placement="top">
                                                <el-button type="text" class="button-text" @click="rejectToEvent(taskScope.row)"><svg-icon name="ib-reject"></svg-icon></el-button>
                                            </el-tooltip>
                                        </template>
                                        <template v-if="['REJECTED', 'ASSIGNED'].indexOf(taskScope.row.status) === -1">
                                            <el-tooltip content="查看报价" placement="top">
                                                <el-button type="text" class="button-text" @click="showOfferDialog(taskScope.row)"><svg-icon name="ib-rmb"></svg-icon></el-button>
                                            </el-tooltip>
                                        </template>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column type="selection" fixed :reserve-selection="true" :selectable="handleSetSelectionEnable" header-align="center" align="center" width="42"></el-table-column>
                <el-table-column label="文件名" fixed sortable="custom" column-key="FILENAME" width="340" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <div class="file-icon">
                            <svg-icon :name="`ic-${config.FILE_TYPE_MATCH['.' + scope.row.fileType] ? config.FILE_TYPE_MATCH['.' + scope.row.fileType].icon : ''}`"></svg-icon>
                        </div>
                        <template v-if="scope.row.docStatus === 'pre-translated'">
                            <router-link :to="`/workbench/editer?id=${scope.row.docId}`" class="table-link">{{ scope.row.originalFilename }}</router-link>
                        </template>
                        <template v-else>{{ scope.row.originalFilename }}</template>
                    </template>
                </el-table-column>
                <el-table-column label="语言方向" width="220">
                    <template slot-scope="scope">
                        <language-direction :source="scope.row.sourceLang" :target="scope.row.targetLang" type="half"></language-direction>
                    </template>
                </el-table-column>
                <el-table-column label="字数 / 字符" prop="sourceWords" min-width="100">
                    <template slot-scope="scope">
                        <span>{{scope.row.sourceWords || '-'}}字 / {{scope.row.sourceCharacters || '-'}}字符</span>
                    </template>
                </el-table-column>
                <!-- <el-table-column label="机翻状态" min-width="130">
                    <template slot-scope="scope">
                        <template v-if="scope.row.docStatus === 'pre-translated'">
                            <svg-icon name="ib-check-circle" class="fonts-15 color-success"></svg-icon>
                            <span class="margin-l-10 color-666666">{{scope.row.docStatusDesc}}</span>
                        </template>
                        <template v-else-if="scope.row.docStatus === 'limited'">
                            <svg-icon name="ib-warning-o" class="fonts-15 color-error"></svg-icon>
                            <span class="margin-x-10">字符流量不足</span><br/>
                            <el-tooltip content="购买成功后，回到此页面点击重译" placement="top">
                                <el-button type="text" class="button-link" @click="showPayCharDialog">购买字符包</el-button>
                            </el-tooltip>
                            <el-button type="text" class="button-link" @click="executeAction('retranslate', scope.row)">重译</el-button>
                        </template>
                        <template v-else-if="scope.row.docStatus.indexOf('error') > -1">
                            <svg-icon name="ib-warning-o" class="fonts-15 color-error"></svg-icon><span class="color-666666 margin-l-10">{{scope.row.docStatusDesc}}</span>
                        </template>
                        <template v-else>
                            <i class="el-icon-loading fonts-15"></i><span class="color-666666 margin-l-10">{{scope.row.docStatusDesc}}</span>
                        </template>
                    </template>
                </el-table-column> -->
                <el-table-column label="进度" header-align="center" align="center" min-width="200">
                    <template slot-scope="scope">
                        <template v-if="scope.row.docStatus === 'pre-translated'">
                            <!-- <div class="task-progress" style="margin-top: 4px; max-width: 150px;">
                                <el-progress :percentage="scope.row.progress" :stroke-width="20" color="#D3F4EC" :show-text="false"></el-progress>
                                <div class="percentage">{{scope.row.progress}}%</div>
                            </div> -->
                            <div class="workflow-wrapper">
                                <div class="list">
                                    <template v-for="(item, index) in scope.row.stages" >
                                        <div class="item" :class="{'active': item.alight}" :key="`workflow-item-${index}`">
                                            <div class="name">{{item.name}}</div>
                                            <div class="point"></div>
                                        </div>
                                        <div class="line" :key="`workflow-line-${index}`" v-if="index < scope.row.stages.length - 1"></div>
                                    </template>
                                </div>
                            </div>
                        </template>
                        <template v-else-if="scope.row.docStatus === 'limited'">
                            <svg-icon name="ib-warning-o" class="fonts-15 color-error"></svg-icon>
                            <span class="margin-x-10">字符流量不足</span><br/>
                            <el-tooltip content="购买成功后，回到此页面点击重译" placement="top">
                                <el-button type="text" class="button-link" @click="showPayCharDialog">购买字符包</el-button>
                            </el-tooltip>
                            <el-button type="text" class="button-link" @click="executeAction('retranslate', scope.row)">重译</el-button>
                        </template>
                        <template v-else-if="scope.row.docStatus.indexOf('error') > -1">
                            <svg-icon name="ib-warning-o" class="fonts-15 color-error"></svg-icon><span class="color-666666 margin-l-10">{{scope.row.docStatusDesc}}</span>
                        </template>
                        <template v-else>
                            <i class="el-icon-loading fonts-15"></i><span class="color-666666 margin-l-10">{{scope.row.docStatusDesc}}</span>
                        </template>
                    </template>
                </el-table-column>
                <el-table-column label="负责人" prop="sourceWords" min-width="160" show-overflow-tooltip>{{doc.createBy}}</el-table-column>
                <el-table-column label="时间" sortable="custom" column-key="CREATE_TIME" min-width="160">
                    <template slot-scope="scope">
                        <svg-icon name="ib-upload-file" className="fonts-18 color-999 margin-r-10"></svg-icon>{{getTimeText(scope.row.createTime)}}
                    </template>
                </el-table-column>
                <!-- <el-table-column label="译后编辑率" width="100"> -->
                    <!-- 译后编辑率 -->-
                <!-- </el-table-column> -->
                <el-table-column label="操作" width="110" fixed="right">
                    <template slot-scope="scope">
                        <template v-if="scope.row.docStatus === 'pre-translated'">
                            <el-tooltip content="拆分" placement="top">
                                <span class="margin-r-10"><el-button type="text" class="button-text" @click="showSplitDialog(scope.row)"><svg-icon name="ib-split-task"></svg-icon></el-button></span>
                            </el-tooltip>
                        </template>
                        <template v-if="scope.row.docStatus === 'pre-translated' || scope.row.docStatus === 'limited' || scope.row.docStatus.indexOf('error') > -1">
                            <el-popover placement="bottom" popper-class="operate-popover">
                                <div>
                                    <template v-if="scope.row.docStatus === 'pre-translated'">
                                        <div class="operate-btn" v-permission="`project:detailRouter:detail`" @click="$router.push(`/workbench/editer?id=${scope.row.docId}`)"><svg-icon name="ib-open-eye"></svg-icon> 查看</div>
                                        <el-dropdown placement="left-start" trigger="hover" class="download-operate-dropdown" @command="(command)=>handleMoreCommand(command, scope.row)">
                                            <div class="operate-btn flex flex-justify-between flex-align-center"><span><svg-icon name="ib-download-1"></svg-icon>下载</span><i class="el-icon-arrow-right float-right"></i></div>
                                            <el-dropdown-menu slot="dropdown">
                                                <el-dropdown-item command="target" v-permission="`project:detailRouter:downloadTranslations`">下载译文</el-dropdown-item>
                                                <el-dropdown-item command="bilingual" v-permission="`project:detailRouter:downloadBilingual`" v-if="['xlf', 'xliff', 'sdlxlf', 'sdlxliff'].indexOf(scope.row.fileType) < 0">下载双语</el-dropdown-item>
                                                <el-dropdown-item command="source" v-permission="`project:detailRouter:downloadOriginal`">下载原文</el-dropdown-item>
                                                <el-dropdown-item command="tmx" v-permission="`project:detailRouter:downloadTMX`">下载TMX</el-dropdown-item>
                                                <el-dropdown-item command="trace" v-permission="`project:detailRouter:downloadEditTrace`">下载编辑痕迹</el-dropdown-item>
                                            </el-dropdown-menu>
                                        </el-dropdown>
                                    <div class="operate-btn" v-permission="`project:detailRouter:history`" @click="executeAction('editWorkflow', scope.row)"><svg-icon name="ib-workflow"></svg-icon>修改流程</div>
                                    <div class="operate-btn" v-permission="`project:detailRouter:history`" @click="showHistory(scope.row)"><svg-icon name="ib-history"></svg-icon>查看历史</div>
                                    </template>
                                    <template v-if="scope.row.docStatus === 'pre-translated' || scope.row.docStatus === 'limited' || scope.row.docStatus.indexOf('error') > -1">
                                        <div class="operate-btn" v-permission="`project:detailRouter:delete`" @click="executeAction('delete', scope.row)"><svg-icon name="ib-trash-o-1"></svg-icon>删除文件</div>
                                    </template>
                                </div>
                                <el-button type="text" class="button-text margin-r-10" slot="reference"><svg-icon name="ib-more"></svg-icon></el-button>
                            </el-popover>
                        </template>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <el-dialog title="联系方式" :visible.sync="contactDialog.show" destroy-on-close width="700px" top="20px">
            <div class="margin-b-20">
                <template v-for="(item, index) in contactDialog.user">
                    <el-row :gutter="10" :key="index">
                        <el-col :span="8">{{item.alias}}</el-col>
                        <el-col :span="8">
                            {{item.phone || '—'}}
                            <span class="margin-l-5 cursor-pointer" @click="copyText(item.phone)">
                                <svg-icon name="ib-copy"></svg-icon>
                            </span>
                        </el-col>
                        <el-col :span="8">
                            {{item.email || '—'}}
                            <span class="margin-l-5 cursor-pointer" @click="copyText(item.email)">
                                <svg-icon name="ib-copy"></svg-icon>
                            </span>
                        </el-col>
                    </el-row>
                </template>
            </div>
        </el-dialog>
        <el-dialog title="退回任务" :visible.sync="rejectDialog.show" @closed="rejectDialogClosed" destroy-on-close width="430px" top="20px">
            <div class="text-center margin-b-10">任务将退回至<span class="text-weight-600 margin-x-5">{{rejectDialog.task.taskTypeDesc}}</span>阶段</div>
            <el-form>
                <el-form-item label="退回原因" required>
                    <el-input v-model="rejectDialog.reason" type="textarea" rows="3" placeholder="填写退回原因"></el-input>
                </el-form-item>
            </el-form>
            <div class="text-center" slot="footer">
                <el-button round plain size="small" @click="rejectDialog.show = false">取消</el-button>
                <el-button type="primary" round size="small" :loading="rejectDialog.loading" @click="submitRejectEvent">确定</el-button>
            </div>
        </el-dialog>
        <el-dialog title="历史记录" :visible.sync="historyDialog.show" destroy-on-close width="600px" top="20px">
            <div class="flex flex-align-center margin-b-20">
                <div class="file-icon" style="height: 40px; width: 40px; font-size: 20px; line-height: 40px">
                    <svg-icon :name="`ic-${config.FILE_TYPE_MATCH['.' + historyDialog.row.fileType] ? config.FILE_TYPE_MATCH['.' + historyDialog.row.fileType].icon : ''}`"></svg-icon>
                </div>
                <span class="fonts-15">{{historyDialog.row.originalFilename}}</span>
            </div>
            <el-timeline>
                <el-timeline-item :timestamp="item.happenTime" placement="top" v-for="(item, i) in historyDialog.list" :key="i">{{item.description}}</el-timeline-item>
            </el-timeline>
        </el-dialog>
        <el-dialog title="拆分" :visible.sync="splitDialog.show" destroy-on-close width="300px" top="20px">
            <div class="flex flex-justify-center">
                <div class="flex flex-align-center">
                    <span class="margin-r-5">拆分为</span>
                    <el-input-number v-model="splitDialog.pieces" controls-position="right" :precision="0" :min="1" :max="10" style="width: 110px;"></el-input-number>
                    <span class="margin-l-5">份</span>
                </div>
            </div>
            <div class="text-center" slot="footer">
                <el-button round plain size="small" @click="splitDialog.show = false">取消</el-button>
                <el-button type="primary" round size="small" :loading="splitDialog.loading" @click="submitSplitEvent">确定</el-button>
            </div>
        </el-dialog>
        <el-dialog title="任务报价" :visible.sync="offerDialog.show" destroy-on-close width="1160px" top="20px">
            <div class="line-height-1.5">
                <div>总金额：{{offerDialog.task.totalPrice}}</div>
                <div>总字数：{{offerDialog.task.sourceWords}}</div>
                <div>计价字数：{{offerDialog.task.priceWordCount}}</div>
                <div>单位价格：{{offerDialog.task.initPrice}}</div>
                <div>额外费率：{{offerDialog.task.additionalRates}}</div>
                <div>计费单位：{{offerDialog.task.priceUnit}}</div>
                <div>所属项目：{{doc.name}}</div>
            </div>
            <el-divider></el-divider>
            <div class="margin-b-10">分析明细</div>
            <el-table :data="[offerDialog.task.quotationDetail]" style="width: 100%;" :header-cell-style="{'background-color': '#f5f7fa'}">
              <el-table-column label="非译元素" prop="untranslated"></el-table-column>
              <el-table-column label="跨文件重复" prop="outerRepeat"></el-table-column>
              <el-table-column label="内部重复" prop="innerRepeat"></el-table-column>
              <el-table-column label="102%" prop="match102"></el-table-column>
              <el-table-column label="101%" prop="match101"></el-table-column>
              <el-table-column label="100%" prop="match100"></el-table-column>
              <el-table-column label="95%-99%" prop="match9599"></el-table-column>
              <el-table-column label="85%-94%" prop="match8594"></el-table-column>
              <el-table-column label="75%-84%" prop="match7584"></el-table-column>
              <el-table-column label="50%-74%" prop="match5074"></el-table-column>
              <el-table-column label="新字" prop="newWord"></el-table-column>
              <el-table-column label="计价字数" prop="priceWordCount"></el-table-column>
              <el-table-column label="总价" prop="workPrice"></el-table-column>

            </el-table>
            <div class="text-center" slot="footer">
                <el-button round plain size="small" @click="offerDialog.show = false">关闭</el-button>
                <el-button type="primary" round size="small" :loading="offerDialog.loading" @click="downloadOfferEvent">下载报价</el-button>
            </div>
        </el-dialog>
        <task-assign-dialog ref="taskAssignDialogDom" @change="assignChangeEvent"></task-assign-dialog>
    </div>
</template>
<script>
import moment from 'moment';
import config from '@/utils/config';
import { docApi, teamApi, projectApi, commonApi } from '@/utils/api';
import TaskAssignDialog from './TaskAssignDialog.vue';
import LanguageDirection from '@/components/LanguageDirection';
export default {
    name: 'FileListInside',
    components: {
        TaskAssignDialog, LanguageDirection,
    },
    props: {
        doc: {
            type: Object, 
            default: {},
        },
        list: {
            type: Array,
            default: []
        },
        time: null,
        reset: '',
        listInit: '',
    },
    data () {
        return {
            moment,
            config,
            dialogShow: false,
            dialogLoading: false,
            contactDialog: {
                show: false,
                user: {},
            },
            rejectDialog: {
                show: false,
                loading: false,
                reason: '',
                task: {},
            },
            historyDialog: {
                show: false,
                row: {},
                list: [],
            },
            splitDialog: {
                show: false,
                loading: false,
                pieces: 1,
                doc: {},
            },
            pickerOptions: function (dTime) {
                return {
                    disabledDate: time => {
                        let current = moment(dTime).format('yyyy-MM-DD');
                        let now = moment(time).format('yyyy-MM-DD');
                        return moment(now).diff(moment(current)) < 0;
                    },
                    // selectableRange: '18:30:00 - 23:59:59'
                }
            },
            rowNumbers: {},
            offerDialog: {
                show: false,
                loading: false,
                task: {},
            },
        }
    },
    methods: {
        executeAction(key,data){
            this.$emit('action', key, data);
        },
        handleMoreCommand(command,item){
            this.executeAction(command, item);
        },
        expandChangeEvent(row, expandedRows ) {
            if(row.docStatus !== "pre-translated"){
                return;
            }
            let docIds = [];
            expandedRows.map(item => {
                docIds.push(item.docId);
            });
            if (docIds.indexOf(row.docId) > -1) {
                this.queryTaskList(row.docId);
            }
        },
        handleSelectionChange(selection) {
            this.$emit('action', 'updateSelected', selection);
        },
        handleSortChange({column, prop, order}) {
            this.$emit('action', 'updateSort', {sortBy: column.columnKey, sortMode: order === 'ascending' ? 'ASC' : order === 'descending' ? 'DESC' : ''})
        },
        generateMultiples(x, n = 500) {
            const multiples = [];
            for (let i = 0; i < n; i++) {
                multiples.push(i * x);
            }
            return multiples;
        },
        queryTaskList(docId) {
            let url = `${docApi.queryFileTaskList}?documentId=${docId}`;
            this.$ajax.get(url).then(res => {
                if(res.status === 200) {
                    let allIds = [];
                    this.list.map(item => {
                        allIds.push(item.docId);
                    });
                    let index = allIds.indexOf(docId);
                    let list = [];
                    let row_length = 1;
                    if(res.data.pipelines.length > 0) {
                        row_length = res.data.pipelines[0].assignments.length;
                    }
                    this.rowNumbers[docId] = this.generateMultiples(row_length);
                    res.data.pipelines.map(item => {
                        let pipeline = item;
                        let assignments = item.assignments;
                        delete pipeline.assignments;
                        let _assignments = assignments.map((item, index) => {
                            return { 
                                ...item, ...pipeline, progress: item.progress,
                                taskName: `${pipeline.range.startSentenceIndex} - ${pipeline.range.endSentenceIndex}`, 
                                rowspan: index === 0 ? assignments.length || 1 : 1, colspan: 1 
                            };
                        })
                        
                        list = list.concat(_assignments);
                    });

                    this.$emit('updateTasks', index, list, res.data.plan);
                }
            })
        },
        assignCommandEvent(command, scope) {
            if (command === 'assign') {
                this.showAssignDialog(scope);
            }else if (command === 'info') {
                this.queryMemberInfo(scope.row);
            }
        },
        showAssignDialog(scope){
            if(scope.row.status === 'FINISHED'){
                this.$message.warning(`当前任务${scope.row.statusDesc}，不能分配。`)
                return;
            }
            this.$refs.taskAssignDialogDom.setShow({task: [scope.row], ids: [scope.row.taskId], doc: this.doc}, true);
        },
        assignChangeEvent(param) {
            this.queryTaskList(param.docId);
        },
        setDeadLineTime(val, pScope, scope) {
            // console.log(val);
            let index = pScope.$index;
            if(new Date(pScope.row.createTime).getTime() > val.getTime()){
                this.$message.error('截止日期不能小于或等于文件上传日期');
                this.list[index].tasks[scope.$index].deadlineTime = '';
                return;
            }
            let deadline = moment(val).format('yyyy-MM-DD HH:mm:ss');
            let url = docApi.batchSetAssignmentDeadline;
            let postData = {
                assignmentIdList: [scope.row.assignmentId],
                deadline,
            };
            this.$ajax.post(url, postData).then(res => {
                if(res.status == 200) {
                    console.log('[INFO] 设置截止日期 %s 成功', deadline)
                }
            }).catch(err => {
                this.list[index].tasks[scope.$index].deadlineTime = '';
            })
        },
        queryMemberInfo(row){
            console.log(row);
            this.contactDialog.user = row.assignee;
            this.contactDialog.show = true;
        },
        rejectToEvent(row) {
            this.rejectDialog.task = row;
            this.rejectDialog.show = true;
        },
        submitRejectEvent() {
            if(!this.rejectDialog.reason) {
                this.$message.error('请填写退回原因');
                return;
            }
            let url = projectApi.submitRejectTo;
            let postData = {
                pipelineId: this.rejectDialog.task.pipelineId,
                toAssignmentId: this.rejectDialog.task.assignmentId,
                reason: this.rejectDialog.reason,
            };
            this.rejectDialog.loading = true;
            this.$ajax.post(url, postData).then(res => {
                if(res.status === 200) {
                    this.rejectDialog.show = false;
                    this.queryTaskList(this.rejectDialog.task.documentId);
                    this.executeAction('reload');
                }
            }).finally(() => {
                this.rejectDialog.loading = false;
            })
        },
        rejectDialogClosed() {
            this.rejectDialog = this.$options.data().rejectDialog;
        },
        showHistory(row) {
            let url = `${projectApi.queryTaskHistory}?documentId=${row.docId}`;
            this.$ajax.get(url).then(res => {
                if(res.status === 200) {
                    this.historyDialog.row = row;
                    this.historyDialog.list = res.data;
                    this.historyDialog.show = true;
                }
            })
        },
        copyText(text){
            let self = this;
            this.$copyText(text).then(function (e) {
                self.$message.success('已复制到剪切板');
            }, function (e) {
                self.$message.error('复制到剪切板失败，请重试');
            })
        },
        showPayCharDialog() {
            this.$router.push({path:'/account/vip', query: {view: 'char', child: true}});
        },
        showSplitDialog(row) {
            this.splitDialog.show = true;
            this.splitDialog.doc = row;
        },
        submitSplitEvent() {
            let url = docApi.submitSplitDoc;
            let postData = {
                documentId: this.splitDialog.doc.docId,
                pieces: this.splitDialog.pieces,
            };
            this.splitDialog.loading = true;
            this.$ajax.post(url, postData).then(res => {
                if(res.status === 200) {
                    this.$message.success('文档拆分成功');
                    this.queryTaskList(this.splitDialog.doc.docId);
                    this.splitDialog.show = false;
                }
            }).finally(()=>{
                this.splitDialog.loading = false;
            });
        },
        getTimeText(t) {
            let tYear = moment(t).year();
            let cYear = moment().year();
            if(tYear === cYear) {
                return moment(t).format(`MM-DD HH:mm`);
            }else {
                return moment(t).format(`YYYY-MM-DD`);
            }
        },
        handleSpanMethod({row, column, rowIndex, columnIndex}) {
            if(columnIndex <= 4){
                // console.log('rowNumbers::', this.rowNumbers)
                if (this.rowNumbers[row.documentId].indexOf(rowIndex) > -1) {
                    return [row.rowspan, row.colspan];
                }else {
                    return [1, 0];
                }
            }else {
                return [1, 1];
            }
        },
        handleRowClassName({row, rowIndex}) {
            if(row.rowspan > 1) {
                return 'row-border-style';
            }
        },
        handleSetSelectionEnable(row, index) {
            return row.docStatus === "pre-translated";
        },
        showOfferDialog(row) {
            console.log(row);
            this.offerDialog.task = row;
            this.offerDialog.show = true;
        },
        downloadOfferEvent() {
            this.offerDialog.loading = true;
            let url = commonApi.batchCreateDownloadTask;
            let postData = {
                title: `下载报价`,
                metadataIdList: [this.offerDialog.task.taskId],
                metadataType: "TMS_TASK_QUOTATION",
                /* moreOptions: {
                    projectId: this.project.projectId,
                    orgId: this.activeOrg.organizationId,
                    taskIds: ids,
                    queryType: 'PROJECT',
                }, */
            };
            this.$ajax.post(url, postData).then(res=>{
                if(res.status === 200){
                    this.$store.dispatch('download/createNotices', res.data.map(item => { return { ...item, show: true}}));
                }
            }).finally(()=>{
                this.offerDialog.loading = false;
            });
        },
    },
    watch: {
        'reset' (newVal, oldVal) {
            this.$refs.fileListTable.clearSelection();
        },
        'listInit' (newVal, oldVal) {
            this.$refs.fileListTable.toggleRowExpansion(this.list[0]);
        }
    }
    
}
</script>
<style lang="scss" scoped>
.file-list-inside-table {
    .table-link {
        color: #000;
        &:hover {
            text-decoration: underline;
        }
    }
    .workflow-wrapper {
        display: inline-block;
        .list {
            display: flex;
            align-items: center;
            .item {
                display: flex;
                flex-direction: column;
                align-items: center;
                color: #999999;
                line-height: normal;
                .name {
                    margin-bottom: 5px;
                    font-size: 12px;
                }
                .point {
                    width: 10px;
                    height: 10px;
                    background-color: #fff;
                    border-radius: 50px;
                    border: 1px solid #C4C4C4;
                    box-sizing: border-box;
                }
                &.finashed {
                    .point {
                        background-color: #1AC49C79;
                        border-color: #1AC49C79;
                    }
                }
                &.active {
                    color: #000000;
                    .point {
                        background-color: #1AC49C;
                        border-color: #1AC49C;
                    }
                }
                z-index: 1;
            }
            .line {
                width: 70px;
                margin-left: -16px;
                margin-right: -8px;
                margin-bottom: -21px;
                border-top: 1px solid #ddd;
                &.dash {
                    border-style: dashed;
                }
            }
        }
    }
    .workflow-step-item {
        display: flex;
        align-items: center;
        justify-content: center;
        color: #999999;
        line-height: normal;
        .point {
            width: 10px;
            height: 10px;
            background-color: transparent;
            border-radius: 50px;
            border: 1px solid #C4C4C4;
            box-sizing: border-box;
        }
        &.finashed {
            .point {
                background-color: #1AC49C79;
                border-color: #1AC49C79;
            }
        }
        &.active {
            color: #000000;
            .point {
                background-color: #1AC49C;
                border-color: #1AC49C;
            }
        }
    }
    ::v-deep .el-table{
        background-color: transparent;
        &::before {
            height: 0;
            width: 0;
            bottom: -10000px;
            background-color: transparent;
        }
        th {
            padding: 10px 0;
            font-size: 14px;
            color: #999999;
        }
        .el-table__expanded-cell {
            background-color: #FFF !important;
            padding: 0;
        }
        .el-table__row {
            td {
                vertical-align: middle;
                background-color: #ffffff;
                font-size: 14px;
                padding: 10px 0;
                
                .cell {
                    .el-button--text {
                        font-size: 14px;
                        svg {
                            font-size: 18px;
                        }
                    }
                }
                
            }
            .el-table__expand-icon {
                font-size: 18px;
                width: 28px;
                height: 28px;
                text-align: center;
                line-height: 28px;
                display: inline-block;
                position: unset;
                .el-icon {
                    position: unset;
                    left: 0;
                    top: 0;
                }
            }
            &:hover {
                td {
                    background-color: #fff;
                }
            }
        }
        
    }
    .expand-table {
        ::v-deep .el-table{
            .el-table__row {
                .cell {
                    line-height: normal;
                    position: relative;
                }
                .cell-status {
                    width: 2px;
                    height: 24px;
                    background-color: #999999;
                    position: absolute;
                    left: 0;
                    top: 8px;
                    border-radius: 20px;
                    &.active {                    
                        background-color: #2979FF;
                    }
                }
                td {
                    vertical-align: middle;
                    border: none;
                    font-size: 14px;
                    padding: 15px 0;
                    /* &:first-child {
                        border-radius: 10px 0 0 10px;
                    }
                    &:last-child {
                        border-radius: 0 10px 10px 0;
                    } */
                    &.eidt-col {
                        padding: 0px !important;
                        cursor: pointer;
                        &:hover {
                            background-color: #f8f8f8;
                        }
                        .cell {
                            height: 40px;
                        }
                        .el-date-editor {
                            .el-input__inner {
                                padding: 0px;
                                border: none;
                                background-color: transparent;
                                font-size: 14px;
                                color: #000000;
                                cursor: pointer;
                            }
                        }
                        .timeout {
                            .el-input__inner{
                                color: #F56C6C;
                            }
                        }
                    }
                }
                &.row-border-style {
                    td {
                        border-top: 1px solid #E6E6E6;
                    }
                }
            }
        }
        .timeout {
            color: #F56C6C;
        }
        .task-label {
            // margin-left: 12px;
        }
    }
    .task-progress {
        display: inline-block;
        width: 198px;
        position: relative;
        .percentage {
            width: 100%;
            position: absolute;
            top: 0;
            line-height: 20px;
            text-align: center;
            color: #1AC49C;
            font-weight: 500;
        }
    }
}
.ASSIGNED {
    color: #FF9900;
}
.REJECTED {
    color: #F56C6C;
}
.file-icon {
    display: inline-block;
    height: 32px;
    width: 32px;
    text-align: center;
    line-height: 32px;
    background-color: #F8F8F8;
    border-radius: 50px;
    font-size: 18px;
    margin-right: 12px;
}
.contact-type-block{
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #F2F2F2;
    font-size: 20px;
    color: #333333;
    display: flex;
    justify-content: center;
    align-items: center;
}

</style>
<style lang="scss">
.operate-popover {
    padding-left: 0px;
    padding-right: 0px;
    .operate-btn {
        padding: 0 15px;
        font-size: 12px;
        line-height: 36px;
        cursor: pointer;
        &:hover {
            background-color: #eaeaea;
        }
        svg {
            font-size: 14px;
            margin-right: 5px;
        }
    }
}
.download-operate-dropdown {
    display: block;
}
.el-picker-panel.hide-time {
    .el-button--text.el-picker-panel__link-btn {
        display: none;
    }
}
</style>
