<!-- 
    选择用户组件
-->
<template>
    <div class="user-selector-component">
        <el-dropdown class="width-full" placement="bottom" trigger="click" @visible-change="visibleChangeEvent" @command="onSelected">
            <template v-if="$slots.default">
                <slot></slot>
            </template>
            <span class="user-avatar-list" :style="{width: `${size}px`}" v-else>
                <el-avatar icon="el-icon-user" :size="size"></el-avatar>
            </span>
            <el-dropdown-menu class="user-selector-wrapper" slot="dropdown">
                <div class="search-content">
                    <el-input v-model="keyword" @input="searchEvent" round size="medium" :placeholder="$t('component.UserSelector_search_input_placeholder')"></el-input>
                </div>
                <div class="search-main">
                    <template v-if="options.length">
                        <template v-for="item in options">
                            <el-dropdown-item class="user-item" :key="item.memberId" :command="item">
                                <div class="active" v-if="selected.length && selected.indexOf(item.memberId) > -1">
                                    <svg-icon name="ib-check-o"></svg-icon>
                                </div>
                                <div style="width: 28px;"><el-avatar :src="item.memAvatar" icon="el-icon-user" :size="28"></el-avatar></div>
                                <div class="user-info">
                                    <div class="flex min-w-0">
                                        <span class="fonts-14 text-omission">{{item.memName}}</span>
                                        <span class="margin-l-10 color-666666">{{item.memPhone}}</span>
                                    </div>
                                    <div class="margin-t-10 color-666666 flex min-w-0">
                                        <span>{{item.roleName}}</span>
                                        <!-- <span class="margin-l-10 text-omission">{{item.memEmail}}</span> -->
                                    </div>
                                </div>
                            </el-dropdown-item>
                        </template>
                    </template>
                    <el-empty :description="$t('component.UserSelector_empty_description')" :image-size="100" v-else></el-empty>
                </div>
            </el-dropdown-menu>
        </el-dropdown>
    </div>
</template>
<script>
export default {
    name: "UserSelector",
    props: {
        multiple: false,
        size: 40,
        params: null,
        selected:{
            type: [Number, String, Array],
            default: '',
        },
        options: {
            type: Array,
            default: [],
        },
    },
    data() {
        return {
            keyword: '',
            searchTimer: null,
        }
    },
    methods: {
        searchEvent() {
            let self = this;
            if(this.searchTimer){
                clearTimeout(this.searchTimer);
                this.searchTimer = null;
            }
            this.searchTimer = setTimeout(()=>{
                self.$emit('search', this.keyword, this.params);
            }, 500);
        },
        visibleChangeEvent(value){
            if(value){
                this.$emit('search', this.keyword, this.params);
            }else {
                if(this.searchTimer){
                    clearTimeout(this.searchTimer);
                    this.searchTimer = null;
                }
                this.keyword = '';
            }
        },
        onSelected(user) {
            this.$emit('change', user, this.params);
        }
    },
    mounted() {

    }

}
</script>
<style lang="scss" scoped>
.user-selector-component {
    .user-avatar-list{

    }
}
.user-selector-wrapper{
    display: flex;
    flex-direction: column;
    width: 240px;
    padding: 5px 0;
    max-height: 383px;
    .search-content {
        display: flex;
        padding: 10px 20px;
        ::v-deep .el-input__inner{
            border-radius: 50px;
        }
    }
    .search-main{
        overflow-y: auto;
        flex: 1;
        .user-item {
            position: relative;
            display: flex;
            padding-top: 15px;
            padding-bottom: 15px;
            
            .active {
                position: absolute;
                left: 4px;
                top: 0;
                height: 100%;
                display: flex;
                align-items: center;
                font-size: 12px;
                color: #00B670;
            }
            .user-info {
                min-width: 0;
                flex: 1;
                margin-left: 7px;
            }
        }

        ::v-deep .el-dropdown-menu__item{
            line-height: 1;
            color: #000000;
            i {
                margin: 0;
            }
        }
        
    }
}
</style>
