<template>
    <div class="card-list-component">
        <span style="position: absolute;top: -1000000px" v-if="['personal', 'pro-limited'].indexOf(userInfo.accountType)>-1">
            <pro-icon></pro-icon>
        </span>
        <div class="card-list">
            <template v-for="(item,i) in list">
                <div class="item" :key="i" @click="executeAction('goto', item)">
                    <div class="item-main">
                        <div class="icon-content">
                            <div class="type-btn">
                                <svg-icon :name="`ic-${fileTypeMatch['.'+item.fileType]?fileTypeMatch['.'+item.fileType].icon:''}`"></svg-icon>
                            </div>
                        </div>
                        <div class="name-content">
                            <el-tooltip :content="item.originalFilename" placement="top-start">
                                <div class="name">{{item.originalFilename}}</div>
                            </el-tooltip>
                        </div>
                        <div class="word-content">
                            <span><template v-if="item.sourceWords">{{item.sourceWords}}</template><span style="color:#0000004D;" v-else>—</span>字</span>
                            <el-divider direction="vertical" class="margin-x-8"></el-divider>
                            <span><template v-if="item.sourceCharacters">{{item.sourceCharacters}}</template><span style="color:#0000004D;" v-else>—</span>字符</span>
                        </div>
                        <div class="word-content">
                            <span class="fonts-12 color-333 margin-r-8" v-if="item.docStatus === 'pre-translated'"><i class="el-icon-circle-check"></i> {{item.docStatusDesc}}</span>
                            <template v-if="userInfo.organizationType === 'ENTERPRISE'">
                                <span class="status" :class="item.status">{{item.statusDesc}}</span>
                            </template>
                        </div>
                        <div class="time-content">{{item.createTime}}</div>
                        <!-- <template v-if="item.docStatus.indexOf('error')>-1">
                            <div class="status-content">
                                <span style="color:#C00E2F;">{{item.docStatusDesc}}</span>
                            </div>
                        </template> -->
                        <template>
                            <template v-if="item.docStatus !== 'pre-translated'">
                                <div class="status-content" v-if="item.docStatus !== 'limited'">
                                    <span><i class="el-icon-loading"></i> {{item.docStatusDesc}}</span>
                                </div>
                            </template>
                            <div class="btn-content relative" v-else>
                                <template v-if="userInfo.organizationType === 'PERSON'">
                                    <template v-if="item.progress!=='0'">
                                        <el-progress :show-text="false" :stroke-width="32" :percentage="item.progress*1||0" color="#D3F4EC"></el-progress>
                                        <div class="progress-text">文件进度 <span class="color-1AC49C text-weight-600 margin-l-5">{{ item.progress }}%</span></div>
                                    </template>
                                    <template v-else>
                                        <el-button plain round block size="small" @click="executeAction('goto', item)" style="width: 70%;" v-if="userInfo.organizationType === 'PERSON'">查看</el-button>
                                    </template>
                                </template>
                                <template v-else>
                                    <div> <!-- v-if="item.taskAssign" -->
                                        <el-dropdown trigger="click" placement="bottom" style="display:block;">
                                            <div @click.stop="getFileTaskList(item)">
                                                <el-progress :show-text="false" :stroke-width="32" :percentage="item.progress*1||0" color="#D3F4EC"></el-progress>
                                                <div class="progress-text hover">文件进度 <span class="color-1AC49C text-weight-600 margin-l-5">{{ item.progress }}%</span></div>
                                            </div>
                                          <el-dropdown-menu slot="dropdown" class="task-list-popover">
                                            <el-table :data="taskList" v-loading="taskListLoading" element-loading-spinner="el-icon-loading">
                                                <el-table-column prop="assigneeAlias" label="负责人"></el-table-column>
                                                <el-table-column prop="taskTypeDesc" label="任务类型" width="80"></el-table-column>
                                                <el-table-column label="进度" width="70">
                                                    <template slot-scope="scope">
                                                        <span class="color-1AC49C">{{scope.row.progress}}%</span>
                                                    </template>
                                                </el-table-column>
                                                <el-table-column label="状态" width="88">
                                                <template slot-scope="scope">
                                                    <span :class="`status ${scope.row.status}`" style="font-size: 14px">{{scope.row.statusDesc}}</span>
                                                </template>
                                                </el-table-column>
                                                <el-table-column label="退回记录" width="95">
                                                    <template slot-scope="scope">被退回{{scope.row.reentries}}次</template>
                                                </el-table-column>
                                            </el-table>
                                          </el-dropdown-menu>
                                        </el-dropdown>
                                    </div>
                                    <!-- <template v-else>
                                        <user-selector v-permission="`project:detailRouter:assignTask`"
                                            :options="ownerOptions" 
                                            :params="item" 
                                            :selected="item.projectTask ? item.projectTask.assigneeId || '' : ''" 
                                            @search="initOwnerOptions" 
                                            @change="ownerSelectedEvent">
                                            <el-button type="text" block size="small" @click.stop class="width-full fonts-14" style="color: #0071E3;">分配</el-button> 
                                        </user-selector>
                                    </template> -->
                                </template>
                            </div>
                        </template>
                    </div>
                    <div class="more-btn" @click.stop v-if="item.docStatus == 'pre-translated' || item.docStatus.indexOf('error')>-1">
                        <el-dropdown placement="bottom" @command="(command)=>handleMoreCommand(command,item)">
                            <span class="padding-5"><svg-icon name="ib-more-v"></svg-icon></span>
                            <el-dropdown-menu slot="dropdown">
                                <el-dropdown-item command="goto" v-permission="`project:detailRouter:detail`">查看</el-dropdown-item>
                                <el-dropdown-item command="allot" divided v-permission="`project:detailRouter:assignTask`" v-if="item.status !=='FINISHED'">分配</el-dropdown-item>
                                <el-dropdown-item command="reject" v-permission="`project:detailRouter:returnToPrevTask`" v-if="item.status !=='COMPLETED'">退回</el-dropdown-item>
                                <template v-if="item.docStatus == 'pre-translated'">
                                    <el-dropdown-item command="translate" divided v-permission="`project:detailRouter:downloadTranslations`">下载译文</el-dropdown-item>
                                    <el-dropdown-item command="all" class="clearfix" v-permission="`project:detailRouter:downloadBilingual`" v-if="['xlf', 'xliff', 'sdlxlf', 'sdlxliff'].indexOf(item.fileType)<0">
                                        下载双语<pro-icon :size="14" style="margin-bottom:-2px;margin-left:5px;" v-if="['personal', 'pro-limited'].indexOf(userInfo.accountType)>-1"></pro-icon>
                                    </el-dropdown-item>
                                    <el-dropdown-item command="source" v-permission="`project:detailRouter:downloadOriginal`">下载原文</el-dropdown-item>
                                    <el-dropdown-item command="tmx" v-permission="`project:detailRouter:downloadTMX`">下载TMX</el-dropdown-item>
                                </template>
                                <el-dropdown-item command="delete" v-permission="`project:detailRouter:delete`" divided style="color:#F56C6C;">删除</el-dropdown-item>
                            </el-dropdown-menu>
                        </el-dropdown>
                    </div>
                    <div class="cover-block" v-if="item.docStatus === 'limited'">
                        <div class="error fonts-20"><svg-icon name="ib-warning-o"></svg-icon></div>
                        <div class="margin-t-10 fonts-14">任务失败</div>
                        <div class="margin-t-10 fonts-12">检测到流量不足</div>
                        <div class="margin-t-20 fonts-14">
                            <span class="color-error cursor-pointer" v-permission="`project:detailRouter:delete`" @click="executeAction('delete', item)">删除</span>
                            <el-tooltip content="购买成功后，回到此页面点击重译" placement="top" v-permission="`project:detailRouter:buy`">
                                <span class="color-link cursor-pointer margin-l-20" @click="showPayCharDialog">购买字符包</span>
                            </el-tooltip>
                            <span class="color-link cursor-pointer margin-l-20" v-permission="`project:detailRouter:retry`" @click="executeAction('retranslate', item)">重译</span>
                        </div>
                    </div>
                    <div class="cover-block" v-if="item.docStatus.indexOf('error') > -1">
                        <div class="error fonts-20"><svg-icon name="ib-warning-o"></svg-icon></div>
                        <div class="margin-t-10 fonts-14">{{item.docStatusDesc}}</div>
                        <div class="margin-t-20 fonts-14">
                            <span class="color-error cursor-pointer" v-permission="`project:detailRouter:delete`" @click="executeAction('delete', item)">删除</span>
                        </div>
                    </div>
                </div>
                
            </template>
        </div>
        <el-dialog title="分配选择" :visible.sync="allotDialogShow" destroy-on-close @open="initOwnerOptions('')" :width="'480px'" top="20px">
            <el-form label-width="80px" style="padding-right: 34px;">
                <template v-for="(item, index) in userSelectorDialogParams">
                    <el-form-item :label="item.taskTypeDesc" :key="index" required>
                        <div class="flex">
                            <user-select v-model="item.assigneeId" :disabled="item.status === 'FINISHED'" :options="ownerOptions" @search="initOwnerOptions" @change="user => ownerSelectedEvent(user, index)"></user-select>
                            <el-button type="primary" round class="margin-l-10" :disabled="item.status === 'FINISHED'" @click="submitAllotUser(index)">确定</el-button>
                        </div>
                    </el-form-item>
                </template>
            </el-form>
        </el-dialog>
        <el-dialog title="退回" :visible.sync="rejectDialogShow" destroy-on-close :width="'430px'" top="20px">
            <div class="text-center">确定要退回到{{ previousTaskDetail && previousTaskDetail.taskTypeDesc }}阶段吗？</div>
            <div slot="footer" class="text-center">
                <el-button plain round size="small" @click="rejectDialogShow = false;">取消</el-button>
                <el-button type="primary" round size="small" :loading="rejectDialogLoading" @click="submitReject">确定</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>
import { mapState } from "vuex";
import { projectApi } from "@/utils/api";
import config from '@/utils/config';
import ProIcon from "@/components/icon/ProIcon";
import UserSelector from '@/components/UserSelector';
import UserSelectorDialog from '@/components/UserSelectorDialog';
import UserSelect from '@/components/UserSelect';
export default {
    components: { ProIcon, UserSelector, UserSelectorDialog, UserSelect, },
    props:{
        type:{
            type:String,
            default:'',
        },
        list:{
            type:Array,
            default:[]
        },
         
    },
    computed:{
        ...mapState({
			userInfo: state => state.user.userData,
            LANGUAGE_DICT: state => state.common.LANGUAGE_DICT,
		}),
    },
    data(){
        return{
            fileTypeMatch:config.FILE_TYPE_MATCH,
            documentStatusDist:config.DOCUMENT_STATUS_DIST,
            taskList: [],
            taskListLoading: false,
            showTaskDocId: null,
            ownerOptions: [],
            userSelectorDialogLoading: false,
            userSelectorDialogParams: null,
            userSelectedItem: null,

            allotDialogShow: false,
            // allotDialogLoading: false,

            previousTaskDetail: {},
            rejectDialogShow: false,
            rejectDialogLoading: false,

        }
    },
    methods:{
        executeAction(key,data){
            this.$emit('action', key, data);
        },
        async handleMoreCommand(command,item){
            if(['personal', 'pro-limited'].indexOf(this.userInfo.accountType) > -1 && command == 'all'){
                this.$router.push('/account/vip');
            }else if(command === 'allot') {
                const res = await this.$ajax.get(`${projectApi.queryDocAssignList}?documentId=${item.docId}`);
                this.userSelectorDialogParams = res.data;
                this.userSelectedItem = item;
                this.allotDialogShow = true;
            }else if(command === 'reject') {
                const res = await this.$ajax.get(`${projectApi.queryDocPreviousTaskDetail}?documentId=${item.docId}`);
                this.previousTaskDetail = res.data.previousTask;
                if (!this.previousTaskDetail) {
                    this.$message.error("当前阶段不可退回");
                    return;
                }
                this.userSelectedItem = res.data.currentTask;
                this.rejectDialogShow = true;
            }else{
                this.executeAction(command, item);
            }
        },
        showPayCharDialog(){
            this.$router.push({path:'/account/vip', query: {view: 'char', child: true}});
        },
        initOwnerOptions(keyword){
            let url = `${projectApi.queryAssignedList}?teamId=${this.userInfo.initTeam.teamId}&keyword=${keyword}`;
            this.$ajax.get(url).then(res => {
                if(res.status === 200) {
                    this.ownerOptions = res.data;
                }
            })
        },
        submitAllotUser(index) {
            let item = this.userSelectorDialogParams[index];
            let postData = {
                taskId: item.taskId,
                assignMemberId: item.assigneeId,
                assignAlias: item.assigneeAlias,
            }
            // this.allotDialogLoading = true;
            let url = projectApi.taskAssign;
            this.$ajax.post(url, postData).then(res => {
                if(res.status === 200) {
                    this.$message.success('分配成功');
                    this.$ajax.get(`${projectApi.queryDocAssignList}?documentId=${this.userSelectedItem.docId}`).then(res => {
                        this.userSelectorDialogParams = res.data;
                    });
                    // this.$emit('action', 'reload');
                }
            }).finally(() => {
                // this.allotDialogLoading = false;
                // this.allotDialogShow = false;
            })
        },
        ownerSelectedEvent(user, index) {
            this.userSelectorDialogParams[index].assigneeId = user.memberId;
            this.userSelectorDialogParams[index].assigneeAlias = user.memName;
        },
        getFileTaskList(item) {
            let url = `${projectApi.queryDocAssignList}?documentId=${item.docId}`;
            this.taskListLoading = true;
            this.$ajax.get(url).then(res => {
                if(res.status === 200) {
                    this.taskList = res.data;
                }
            }).finally(() => {
                this.taskListLoading = false;
            })
        },

        submitReject() {
            let url = projectApi.submitReject;
            this.rejectDialogLoading = true;
            this.$ajax.post(url, {taskId: this.userSelectedItem.taskId}).then(res => {
                if(res.status === 200) {
                    this.rejectDialogShow = false;
                    this.$message.success("退回成功");
                    this.$emit('action', 'reload');
                }
            }).finally(() => {
                this.rejectDialogLoading = false;
            });
        },
        


    },
    mounted() {
        
    }
}
</script>
<style lang="scss" scoped>
@import '@/assets/css/variables.scss';
.card-list-component{
    .card-list{
        margin: 0 90px;
        display: grid;
        grid-gap: 28px;
        grid-template-columns: repeat(auto-fill,minmax(190px,1fr));
        > .item {
            height: 226px;
            position: relative;
            background: #FFFFFF;
            box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.05);
            border-radius: 10px;
            padding: 20px 15px;
            position: relative;
            cursor: pointer;
            border: 1px solid #ffffff;
            box-sizing: border-box;
            transition: border .3s;
            
            > .item-main{
                .icon-content{
                    display: flex;
                    justify-content: center;
                    .type-btn{
                        width: 50px;
                        height: 50px;
                        background-color: #F8F8F8;
                        border-radius: 50%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        font-size: 31px;
                    }
                }
                .name-content{
                    margin-top: 10px;
                    .name{
                        // font-weight: 400;
                        font-size: 15px;
                        text-align: center;
                        white-space:nowrap;  
                        text-overflow:ellipsis;
                        overflow: hidden;
                    }

                }
                .language-content{
                    margin-top: 10px;
                    font-size: 12px;
                    text-align: center;
                }
                .word-content{
                    margin-top: 10px;
                    font-size: 12px;
                    text-align: center;
                }
                .time-content{
                    margin-top: 10px;
                    font-size: 12px;
                    text-align: center;
                    color: #808080;
                }
                .btn-content{
                    margin-top: 15px;
                    text-align: center;
                }
                .status-content{
                    text-align: center;
                    font-size: 14px;
                    margin-top: 25px;
                }
                .progress-text {
                    position: absolute;
                    top: 0;
                    right: 0;
                    width: 100%;
                    height: 100%;
                    font-size: 12px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    &.hover {
                        &:hover {
                            color: #1AC49C;
                        }
                    }
                }
                ::v-deep .el-progress-bar__outer{
                    background-color: #F8F8F8;
                }
                .status {
                    font-size: 12px;
                    &.to_start {
                        color: #FE8E0B;
                    }
                    &.in_progress {
                        color: #2979FF;
                    }
                    &.completed,
                    &.finished {
                        color: #00B670;
                    }
                }
            }
            > .more-btn{
                // display: none;
                position: absolute;
                top: 10px;
                right: 10px;
                svg{
                    font-size:20px;
                }
            }
            &:hover,&:focus{
                border: 1px solid #DFDFDF;
                box-sizing: border-box;
            }
            > .cover-block{
                position: absolute;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                background: #ffffffE6;
                border-radius: 10px;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                font-size: 14px;
                cursor: default;
                .error{
                    color: $error-color;
                }
                .info{
                    margin-top: 10px;
                }
                .delete{
                    color: $a-color;
                    margin-top: 20px;
                    cursor: pointer;
                    &:hover{
                        color: $a-hover-color;
                    }
                }
            }
        }
    }
}
.status {
    font-size: 12px;
    &.TO_START {
        color: #FE8E0B;
    }
    &.IN_PROGRESS {
        color: #2979FF;
    }
    &.COMPLETED,
    &.FINISHED {
        color: #00B670;
    }
}
</style>
<style lang="scss">
.task-list-popover{
    padding: 0;
    .el-table {
        color: #000000;
    }
    .el-table .cell {
        text-align: center;
    }
    .el-table thead{
        color: #000000;
    }
    .el-table th {
        background-color: #F2F2F2;
        font-size: 13px;
        // padding: 7px 0;
        padding-top: 7px;
        padding-bottom: 7px;
    }
    .el-table td {
        padding-top: 8px;
        padding-bottom: 8px;
    }
    .el-loading-spinner{
        margin-top: -9px;
        i{
            color: #2979FF;
            font-size: 18px;
        }
    }
}
    
</style>
