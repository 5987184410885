<template>
    <div class="file-analysis-component">
        <div class="flex flex-align-center flex-justify-between padding-y-12">
            <div class="flex">
                <div class="fonts-15 flex flex-align-center">
                    <span>统计维度：</span>
                    <el-select v-model="targetLang" @change="selectTargetLangEvent" size="small" placeholder="请选择" style="width:120px;">
                        <template v-for="item in project.targetLangs">
                            <el-option :key="item.key" :value="item.key" :label="item.name"></el-option>
                        </template>
                    </el-select>
                    <!-- <span class="margin-l-40">当前文件总字数：{{'12345678'}}</span>
                    <span class="margin-l-40">重复字数：{{'12345678'}}</span> -->
                </div>
                <!-- <div class="fonts-15 flex flex-align-center margin-l-10">
                    <span>计价比率：</span>
                    <el-button plain size="small">设置计价</el-button>
                </div> -->
            </div>
            <div>
                <el-button round plain size="medium" :loading="downloadLoading" @click="downloadFileAnalysis"><svg-icon name="ib-download" v-if="!downloadLoading"></svg-icon> 下载文件分析报告</el-button>
            </div>
        </div>
        <template v-if="projectData">
            <div class="analysis-title">总计</div>
            <el-table :data="projectData.details" style="width: 100%;">
                <el-table-column label="">
                    <template slot-scope="scope">
                        <el-tooltip content="50%以下匹配都属于新字" placement="right" v-if="scope.row.matchType === 'NEW'">
                            <span>{{scope.row.label}} <svg-icon name="ib-question"></svg-icon></span>
                        </el-tooltip>
                        <el-tooltip content="指当前段落100%匹配基础上,前一段落或后一段落匹配。" placement="right" v-else-if="scope.row.matchType === 'MATCH_101'">
                            <span>{{scope.row.label}} <svg-icon name="ib-question"></svg-icon></span>
                        </el-tooltip>
                        <el-tooltip content="指的是不仅当前段落与记忆库存储的相同,而且之前和之后的段落也是相同的" placement="right" v-else-if="scope.row.matchType === 'MATCH_102'">
                            <span>{{scope.row.label}} <svg-icon name="ib-question"></svg-icon></span>
                        </el-tooltip>
                        <span v-else>{{scope.row.label}}</span>
                    </template>
                </el-table-column>
                <el-table-column>
                    <template slot="header">
                        <el-tooltip content="百分比结果四舍五入保留整数，仅供参考" placement="top">
                            <span>% <svg-icon name="ib-question"></svg-icon></span>
                        </el-tooltip>
                    </template>
                    <template slot-scope="scope">{{scope.row.percentage}}%</template>
                </el-table-column>
                <el-table-column>
                    <template slot="header">
                        <el-tooltip content="表示的是字符的个数，一个中文字算作一个字符，一个由5个英文字母组成的英文单词算作5个字符。" placement="top">
                            <span>字符数 <svg-icon name="ib-question"></svg-icon></span>
                        </el-tooltip>
                    </template>
                    <template slot-scope="scope">{{scope.row.characters}}</template>
                </el-table-column>
                <el-table-column>
                    <template slot="header">
                        <el-tooltip content="表示的是单词的个数，一个中文字即算一个字；英文一个单词算一个字。" placement="top">
                            <span>字数 <svg-icon name="ib-question"></svg-icon></span>
                        </el-tooltip>
                    </template>
                    <template slot-scope="scope">{{scope.row.words}}</template>
                </el-table-column>
                <el-table-column label="句段" prop="segments"></el-table-column>
                <el-table-column label="中朝字数">
                    <template slot="header">
                        <el-tooltip content="表示是中文字符和朝鲜语单词，一般就是纯中文字数，不包括英文单词和阿拉伯数字。" placement="top">
                            <span>中朝字数 <svg-icon name="ib-question"></svg-icon></span>
                        </el-tooltip>
                    </template>
                    <template slot-scope="scope">{{scope.row.ckWords}}</template>
                </el-table-column>
            </el-table>
        </template>
        <template v-if="docData.length">
            <template v-for="item in docData">
                <div class="analysis-title" :key="`title-${item.documentId}`">{{item.documentName}}</div>
                <el-table :data="item.details" style="width: 100%;" :key="`table-${item.documentId}`">>
                    <el-table-column label="">
                        <template slot-scope="scope">
                            <el-tooltip content="50%以下匹配都属于新字" placement="right" v-if="scope.row.matchType === 'NEW'">
                                <span>{{scope.row.label}} <svg-icon name="ib-question"></svg-icon></span>
                            </el-tooltip>
                            <el-tooltip content="指当前段落100%匹配基础上,前一段落或后一段落匹配。" placement="right" v-else-if="scope.row.matchType === 'MATCH_101'">
                                <span>{{scope.row.label}} <svg-icon name="ib-question"></svg-icon></span>
                            </el-tooltip>
                            <el-tooltip content="指的是不仅当前段落与记忆库存储的相同,而且之前和之后的段落也是相同的" placement="right" v-else-if="scope.row.matchType === 'MATCH_102'">
                                <span>{{scope.row.label}} <svg-icon name="ib-question"></svg-icon></span>
                            </el-tooltip>
                            <span v-else>{{scope.row.label}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column>
                        <template slot="header">
                            <el-tooltip content="百分比结果四舍五入保留整数，仅供参考" placement="top">
                                <span>% <svg-icon name="ib-question"></svg-icon></span>
                            </el-tooltip>
                        </template>
                        <template slot-scope="scope">{{scope.row.percentage}}%</template>
                    </el-table-column>
                    <el-table-column>
                        <template slot="header">
                            <el-tooltip content="表示的是字符的个数，一个中文字算作一个字符，一个由5个英文字母组成的英文单词算作5个字符。" placement="top">
                                <span>字符数 <svg-icon name="ib-question"></svg-icon></span>
                            </el-tooltip>
                        </template>
                        <template slot-scope="scope">{{scope.row.characters}}</template>
                    </el-table-column>
                    <el-table-column>
                        <template slot="header">
                            <el-tooltip content="表示的是单词的个数，一个中文字即算一个字；英文一个单词算一个字。" placement="top">
                                <span>字数 <svg-icon name="ib-question"></svg-icon></span>
                            </el-tooltip>
                        </template>
                        <template slot-scope="scope">{{scope.row.words}}</template>
                    </el-table-column>
                    <el-table-column label="句段" prop="segments"></el-table-column>
                    <el-table-column label="中朝字数">
                        <template slot="header">
                            <el-tooltip content="表示是中文字符和朝鲜语单词，一般就是纯中文字数，不包括英文单词和阿拉伯数字。" placement="top">
                                <span>中朝字数 <svg-icon name="ib-question"></svg-icon></span>
                            </el-tooltip>
                        </template>
                        <template slot-scope="scope">{{scope.row.ckWords}}</template>
                    </el-table-column>
                </el-table>
            </template>
        </template>
    </div>
</template>
<script>
import { projectApi, commonApi } from '@/utils/api';
export default {
    props: {
        project: {
            type: Object,
            default: null,
        }
    },
    data() {
        return {
            projectData: null,
            docData: [],
            downloadLoading: false,
            targetLang: '',
        }
    },

    methods: {
        initAnalysis() {
            let url = `${projectApi.queryDocAnalysis}?projectId=${this.project.projectId}&targetLang=${this.targetLang}`;
            this.$ajax.get(url).then(res => {
                if(res.status === 200) {
                    this.projectData = res.data.projectData;
                    this.docData = res.data.docData;
                }
            })
        },
        selectTargetLangEvent(val){
            this.initAnalysis();
        },
        downloadFileAnalysis() {
            // let url = `${projectApi.downloadDocAnalysis}?projectId=${this.project.projectId}`;
            let url = commonApi.batchCreateDownloadTask;
            this.downloadLoading = true;
            this.$ajax.post(url, {
                title: `${this.project.name}文件分析报告`,
                metadataIdList: [this.project.projectId],
                metadataType: "TMS_PROJECT_ANALYSIS",
            }).then(res => {
                if(res.status === 200) {
                    this.downloadLoading = true;
                    this.$store.dispatch('download/createNotices', res.data.map(item => { return { ...item, show: true} }));
                }
            }).finally(() => {
                this.downloadLoading = false;
            });
        },
    },
    mounted() {
        // this.initAnalysis();
        this.targetLang = this.project.targetLangs[0].key;
    },
    watch: {
        project(newVal) {
            this.targetLang = newVal.targetLangs[0].key;
        }
    }
}
</script>
<style lang="scss" scoped>
.file-analysis-component {
    margin-bottom: 40px;
    .analysis-title{
        height: 44px;
        background-color: #E9F8F2;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 14px;
    }
}
</style>
