<!-- 选择用户下拉列表组件 -->
<template>
    <el-select class="user-select" v-model="selected" @change="onSelected" :disabled="disabled" remote filterable reserve-keyword :clearable="clearable" :remote-method="searchEvent" :placeholder="$t('component.UserSelect_select_placeholder')" style="width: 100%;">
        <template v-for="item in options">
            <el-option class="user-selector-option" :key="item.memberId" :value="item.memberId" :label="item.memName">
                <div class="flex flex-algin-center">
                    <el-avatar :src="item.memAvatar" icon="el-icon-user" :size="28"></el-avatar>
                    <div class="margin-l-10">
                        <div>
                            <span>{{item.memName}}</span>
                            <span class="margin-l-10 color-666666 fonts-12">{{item.memPhone}}</span>
                        </div>
                        <div class="margin-t-10 color-666666 fonts-12 ">{{item.roleName}}</div>
                    </div>
                </div>
            </el-option>
        </template>
    </el-select>
</template>
<script>
export default {
    name: "UserSelect",
    props: {
        value: {
            type: [String, Number, Object],
            default: '',
        },
        multiple: false,
        clearable: false,
        disabled: false,
        options: {
            type: Array,
            default: [],
        },
    },
    data () {
        return {
            selected: '',
            searchTimer: null,
        }
    },
    methods: {
        onSelected (val) {
            this.user = this.options.filter(item => item.memberId === this.selected)[0];
            this.$emit('change', this.user);
        },
        searchEvent(query) {
            let self = this;
            if(this.searchTimer){
                clearTimeout(this.searchTimer);
                this.searchTimer = null;
            }
            this.searchTimer = setTimeout(()=>{
                self.$emit('search', query/*,  this.params*/) ;
            }, 300);
        },
    },
    created () {
        this.selected = this.value;
    },
    watch: {
        value(newVal, oldVal){
            this.selected = newVal;
        }
    }
}
</script>
<style lang="scss" scoped>
.user-selector-option {
    height: auto;
    line-height: 1;
    padding: 8px 20px;
    color: #000000;
}
.user-select {
    ::v-deep .el-input__inner {
        border-radius: 20px;
    }
}
</style>
